@import '../../theme/variables';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 322rem;
  animation: fadeIn 0.5s;
}

.events {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  height: calc(100vh - 28rem);
  padding-top: 3rem;
  overflow-y: scroll;
  position: relative;

  &Details {
    @extend .events;

    position: relative;
    padding-top: 0px;
    width: 104.5rem;
  }

  &__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 11rem;
    max-width: 105rem;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 1081px) {
      max-width: 220rem;
    }
  }

  &__buttonBack {
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    z-index: 500;
    top: 2rem;
    left: 2rem;
    border: none;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    transition: all 0.2s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__buttonBackIcon {
    fill: $color-white;
    transform: rotate(90deg);
    margin-right: 0.7rem;
    width: 4.5rem;
    height: 3rem;
  }
}

.picker {
  display: flex;
  position: absolute;
  top: 3.2rem;
  left: 32rem;
  width: 55rem;
  height: 7.5rem;

  &__box {
    display: flex;
    padding: 1rem;
    width: 25rem;
    height: 7.5rem;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 0.6rem;
    margin-right: 2rem;
  }

  &__icon {
    width: 5rem;
    height: 5.2rem;
  }

  &__input {
    width: 18rem;
    height: 8rem;
    background: inherit;
    border: none;
    box-shadow: none;
    border-radius: 0.6rem;
    transform: translateY(-1.2rem);
    padding-left: 4.5rem;
    font-size: $font-size-l;

    &:focus {
      outline-style: none;
    }
  }
}
