@import '../../theme/variables';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: calc(100vh - 28rem);
  animation: fadeIn 0.5s;
}

.innerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
}

.footer {
  width: 104.2rem;
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100rem;
  height: 16rem;
  border-radius: 0.6rem;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  margin-bottom: 3rem;

  &__arrow {
    fill: $color-white;
    transform: rotate(90deg);
    margin-right: 0.5rem;
    width: 3rem;
    height: 4rem;

    &Right {
      @extend .image__arrow;

      transform: rotate(-90deg);
      margin-right: 0;
      margin-left: 0.5rem;
    }
  }

  &__button {
    background-color: inherit;
    border: none;
    position: absolute;
    left: 3rem;
    width: 6rem;
    height: 6rem;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;

    &Right {
      @extend .image__button;

      left: auto;
      right: 3rem;
    }
  }

  &__location {
    display: flex;
    align-items: center;
    position: absolute;
    font-weight: $font-bold;
    font-size: 3rem;
    line-height: $line-height-xl;
    color: $color-white;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 2rem 3rem;
    border-radius: 0.6rem;
  }

  &__icon {
    width: 5.6rem;
    height: 5.6rem;
    fill: white;
    margin-right: 1.5rem;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.6rem;
  }
}
