//----- Colors -----

$color-black: #000000;
$color-blue: #1979d4;
$color-dark: #323232;
$color-green: #1c6434;
$color-gray: #7e8d94;
$color-grayDark: #4e4e4e;
$color-orange: #ffa800;
$color-red: #dd4747;
$color-redDark: #ab3d3d;
$color-white: #ffffff;

//----- Fonts -----

$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semiBold: 600;
$font-bold: 700;

$font-size-s: 1.4rem;
$font-size-m: 1.6rem;
$font-size-l: 1.8rem;
$font-size-xl: 2.1rem;
$font-size-xll: 3.6rem;

$line-height-s: 2rem;
$line-height-m: 2.6rem;
$line-height-l: 2.7rem;
$line-height-xl: 3.1rem;
$line-height-xll: 5.4rem;

//----- Margins -----

$margin-s: 1rem;
$margin-m: 2rem;
$margin-l: 5rem;
$margin-xl: 10rem;
$margin-xxl: 14rem;

//----- Paddings -----

$padding-s: 1rem;
$padding-m: 2rem;
$padding-l: 3rem;
$padding-xl: 5rem;
$padding-xxl: 10rem;
