@import '../../theme/variables';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.timetable {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 0.5s;

  &__background {
    position: absolute;
    width: 43rem;
    height: 43rem;
    top: 40%;
    z-index: -100;
  }

  &__title {
    font-weight: $font-bold;
    font-size: 3rem;
    line-height: 4.5rem;
    text-align: center;
    margin: 2rem 0;
  }
}

.buttonBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 7rem;
  position: relative;
  height: 7.7rem;
  width: 87rem;
}

.button {
  display: flex;
  align-items: center;
  position: absolute;
  left: 0px;
  width: 39.5rem;
  height: 7.8rem;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 0.6rem;
  padding: 0 5rem 0 1.5rem;

  &__label {
    font-weight: $font-bold;
    font-size: $font-size-l;
    margin-left: 1.5rem;

    &Green {
      @extend .button__label;

      color: $color-green;
    }
  }

  &__icon {
    fill: $color-green;
    width: 4.4rem;
    height: 4.4rem;
  }

  &__iconArrow {
    fill: $color-green;
    width: 3.7rem;
    height: 2rem;
    margin: 0 3rem;
  }
}

.footer {
  width: 104.5rem;
  position: absolute;
  bottom: 0;
}
