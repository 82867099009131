@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.trail {
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  position: relative;
  overflow-y: scroll;
  max-width: 104.4rem;
  animation: fadeIn 0.5s;
}
