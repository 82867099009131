@import '../../theme/variables';

.card {
  position: relative;
  display: flex;
  align-items: center;
  width: 100rem;
  height: 19rem;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0.6rem;
  padding: 2rem 3rem;
  margin-bottom: 1.5rem;

  &__arrow {
    position: absolute;
    top: 2rem;
    left: 34.8rem;
    fill: $color-green;
    width: 3.7rem;
    height: 2rem;
  }

  &__bus {
    display: flex;
    align-items: center;
    margin-right: 6rem;
  }

  &__date {
    font-weight: $font-light;
    font-size: $font-size-l;
    line-height: $line-height-l;
  }

  &__end {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    height: 14rem;
  }

  &__minute {
    font-weight: $font-regular;
    font-size: 3rem;
    line-height: 4.5rem;
  }

  &__numberBus {
    font-weight: $font-semiBold;
    font-size: 3rem;
    color: $color-green;
    margin-right: 0.8rem;
  }

  &__nameStation {
    font-weight: $font-bold;
    font-size: $font-size-xl;
    line-height: $line-height-xl;
  }

  &__innerWrapper {
    display: flex;
    flex-direction: column;
  }

  &__icon {
    width: 7rem;
    height: 7rem;
    margin-right: 3rem;
  }

  &__line {
    position: absolute;
    left: 36.5rem;
    background-color: #c4c4c4;
    width: 1px;
    height: 11rem;
    transform: translateY(15px);
  }

  &__time {
    font-weight: $font-semiBold;
    font-size: $font-size-xll;
    line-height: $line-height-xll;
  }

  &__station {
    width: 20rem;
    margin-right: 1.5rem;
  }

  &__stationLong {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 33rem;
    height: 14rem;

    &Time {
      @extend .card__stationLong;

      width: 20rem;
      justify-content: center;
      align-items: flex-end;
    }
  }
}
