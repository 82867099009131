@import '../../theme/variables';

span {
  font-weight: $font-bold;
}

.object {
  position: absolute;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  z-index: -1000;
  height: 100%;
  overflow-y: scroll;
  max-width: 104.5rem;

  &Full {
    @extend .object;

    top: 0;
  }

  &__descriptionBox {
    padding: 3rem 6rem 0 6rem;
  }

  &__imageBox {
    display: flex;
    position: relative;
    overflow-x: scroll;
    max-width: 100%;
    min-height: 23rem;
    height: 23rem;

    :last-child {
      margin-right: 0px;
    }

    &Full {
      @extend .object__imageBox;

      min-height: 33rem;
      height: 33rem;
    }
  }

  &__image {
    max-width: 100%;
    height: 100%;
    margin-right: 1.2rem;
    opacity: 1;
    transition: ease 0.5s all;

    &Hide {
      @extend .object__image;

      opacity: 0;
    }
  }

  &__imagePlaceHolder {
    min-width: 60rem;
    height: 100%;
    margin-right: 1.2rem;
    background-color: rgba(28, 100, 52, 0.1);
  }

  &__name {
    font-weight: $font-semiBold;
    font-size: $font-size-xll;
    line-height: $line-height-xll;
  }

  &__category {
    font-weight: $font-semiBold;
    font-size: $font-size-xl;
    line-height: $line-height-xl;
    color: $color-blue;
    font-style: italic;
  }
}

.info {
  display: flex;
  justify-content: space-around;
  margin: 3rem 0;
  padding-bottom: $padding-l;
  border-bottom: 1px solid #cccccc;
  position: relative;

  &__descriptionBox {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__dayBox {
    position: absolute;
    z-index: 1000;
    top: 6rem;
    left: 0;
    background: #ffffff;
    border: 1px solid #ececec;
    box-sizing: border-box;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.19);
    border-radius: 0.6rem;
    padding: 0px 15px 20px;
    min-width: 28rem;

    &::before {
      content: url(../../assets/tangle.png);
      display: inline-block;
      position: relative;
      top: -18px;
      left: 140px;

      @media screen and (min-width: 950px) {
        left: 200px;
      }
    }

    @media screen and (min-width: 950px) {
      top: 80px;
    }
  }

  &__dayBoxDay {
    display: flex;
    justify-content: space-between;
    font-size: $font-size-l;
    line-height: $line-height-xl;

    &Active {
      @extend .info__dayBoxDay;

      font-weight: $font-bold;
      color: $color-green;
    }
  }

  &__icon {
    width: 5.8rem;
    height: 5.8rem;
  }

  &__locationBox {
    display: flex;
    position: absolute;
    z-index: 800;
    top: 16rem;
    left: 0;
    background: #ffffff;
    border: 1px solid #ececec;
    box-sizing: border-box;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.19);
    border-radius: 0.6rem;
    padding: 1.1rem 4rem 0.3rem 0;
    min-width: 28rem;
    font-size: $font-size-s;

    &::before {
      content: url(../../assets/tangle.png);
      display: inline-block;
      position: relative;
      top: -26px;
      left: 40px;

      @media screen and (min-width: 950px) {
        top: -28px;
        left: 70px;
      }
    }

    @media screen and (min-width: 950px) {
      top: 17rem;
      padding: 1.1rem 2.5rem 1rem 0;
    }
  }

  &__logoBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #cccccc;
    margin-top: 3rem;
    padding-top: 3rem;
    width: 100%;
  }

  &__logoBoxParagraph {
    font-size: $font-size-l;
    line-height: $line-height-l;
    color: #7e8d94;
    font-style: italic;
    max-width: 21rem;
  }

  &__logoBoxLogo {
    max-width: 22rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &__photo {
    margin: 2rem auto 3rem auto;
    max-width: 100%;
    max-height: 44rem;
    opacity: 1;
    transition: ease 0.5s all;

    &Hide {
      @extend .info__photo;

      opacity: 0;
    }
  }

  &__photoPlaceHolder {
    margin: 2rem auto 3rem auto;
    width: 71rem;
    height: 44rem;
    background-color: rgba(28, 100, 52, 0.1);
  }

  &__paragraph {
    font-size: $font-size-l;
    line-height: $line-height-l;
    margin-bottom: 1rem;
  }

  &__timeArrow {
    fill: $color-green;
    width: 1.5rem;
    height: 2.5rem;
  }

  &__timeButton {
    display: flex;
    align-items: center;
    margin-left: 2rem;
    background-color: inherit;
    border: none;
  }

  &__timeLabel {
    font-weight: $font-regular;
    font-size: $font-size-l;
    line-height: $line-height-l;
    margin-right: 1rem;

    &Close {
      @extend .info__timeLabel;

      font-size: 1.3rem;
      font-weight: $font-bold;

      @media screen and (min-width: 950px) {
        font-size: $font-size-l;
      }
    }
  }
}

.infoLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;

  @media screen and (min-width: 950px) {
    width: 50%;
  }

  &__box {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__linkToMap {
    display: flex;
    align-items: center;
  }

  &__paragraph {
    font-weight: $font-regular;
    font-size: $font-size-l;
    line-height: $line-height-l;
    margin-left: $margin-m;
  }
}

.infoRight {
  display: flex;
  justify-content: flex-end;
  width: 50%;

  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 17rem;
    margin-right: $margin-m;
  }

  &__paragraph {
    font-weight: $font-semiBold;
    font-size: $font-size-l;
    line-height: $line-height-l;
    margin-top: $margin-s;
    text-align: center;
    color: $color-green;
  }

  &__qr {
    width: 17rem;
    height: 17rem;
  }
}

.weather {
  display: flex;
  align-items: center;
  padding-bottom: $padding-l;
  border-bottom: 1px solid #cccccc;

  &__box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &Border {
      @extend .weather__box;

      padding-bottom: 0;
      border-bottom: none;
      border-right: 1px solid #cccccc;
    }
  }

  &__icon {
    width: 13rem;
    height: 13rem;
  }

  &__name {
    font-weight: $font-semiBold;
    font-size: 2.4rem;
    line-height: $line-height-xll;
    text-align: center;
  }

  &__temperature {
    margin-top: $margin-m;
    font-weight: $font-semiBold;
    font-size: $font-size-xll;
    text-align: center;
  }
}

.location {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: $font-size-xll;
  margin-bottom: 3rem;

  &__name {
    display: flex;
    align-items: center;
    font-size: $font-size-xl;
    font-weight: $font-semiBold;
    font-style: italic;
    color: $color-green;
    text-decoration: underline;
  }

  &__icon {
    width: 4.4rem;
    height: 4.4rem;
    margin: 0 1.5rem;
  }
}

.berg {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 2rem;

  &__content {
    font-size: $font-size-s;
    line-height: $line-height-m;
  }

  &__logo {
    width: 7rem;
    height: auto;
    margin-right: 0.5rem;
  }
}

.footer {
  display: flex;
}

.footerDisabled {
  display: none;
}
