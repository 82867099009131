.chart {
  width: 100rem;
  padding: 6rem 0 4rem 5rem;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0.6rem;
  overflow-x: scroll;

  &__name {
    font-weight: 600;
    font-size: 3rem;
    line-height: 4.5rem;
    margin-bottom: 4rem;
  }

  &__icon {
    position: relative;
    transform: translateX(4.4rem);
    width: 5rem;
    margin-right: 8.5rem;
  }
}

.chartBox {
  min-width: 155rem;
  margin-bottom: 5rem;
}

.legendBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2rem;
  align-items: flex-start;

  &__box {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__line {
    width: 5.5rem;
    height: 0.7rem;
    margin-right: 0.5rem;
    background-color: #323232;

    &Dot {
      @extend .legendBox__line;

      width: 0.5rem;
    }
  }

  &__paragraph {
    color: #323232;
    font-weight: 600;
    font-size: 1.6rem;
    margin-left: 1.5rem;
  }
}
