@import '../../theme/variables';

.button {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  width: 24rem;
  height: 7.5rem;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 0.6rem;
  margin-left: 33rem;
  transition: 0.2s all;
  position: absolute;
  top: 31rem;
  z-index: 1000;

  &Active {
    @extend .button;

    background: #1c6434;
  }

  &__clock {
    fill: #1c6434;
    width: 5.3rem;
    height: 5.3rem;

    &Active {
      fill: $color-white;
      width: 5.3rem;
      height: 5.3rem;
    }
  }

  &__close {
    position: absolute;
    color: $color-white;
    top: 0;
    right: 1rem;
    font-weight: $font-bold;
    font-size: 2.5rem;
  }

  &__label {
    font-weight: $font-bold;
    font-size: $font-size-l;
    line-height: $line-height-l;
    letter-spacing: 0.1rem;
    color: $color-dark;
    margin-left: $margin-s;
    text-align: left;
    max-width: 15rem;

    &Active {
      @extend .button__label;

      color: $color-white;
    }
  }
}
