@import '../../theme/variables';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.landing {
  margin-left: auto;
  margin-right: auto;
  animation: fadeIn 0.5s;
}

.buttonPoint {
  position: absolute;
  display: flex;
  align-items: center;
  background-color: inherit;
  border: none;
  z-index: 1000;

  &__icon {
    width: 5.5rem;
    height: 7.5rem;
  }

  &__label {
    font-weight: $font-bold;
    font-size: $font-size-m;
    line-height: $line-height-s;
    margin-left: 0.5rem;
    letter-spacing: 0.1rem;
    max-width: 16rem;
    text-align: left;

    &Red {
      @extend .buttonPoint__label;

      color: $color-red;
    }
  }
}

.card {
  position: absolute;
  bottom: 0;
  background-color: $color-white;
  width: 100%;
  transition: all 0.2s ease-out;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.25);
  z-index: 5000;

  &Close {
    @extend .card;

    bottom: -100rem;
  }

  &__boxButtons {
    position: relative;
    height: 7rem;
    margin-bottom: 3rem;
    max-width: 104.5rem;
    margin-left: auto;
    margin-right: auto;
  }

  &__buttonMore {
    background-color: inherit;
    position: absolute;
    top: 2rem;
    left: 46%;
    border: none;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    transition: all 0.2s ease-out;

    &Full {
      @extend .card__buttonMore;

      background-color: rgba(0, 0, 0, 0.7);
      left: 2rem;
    }
  }

  &__container {
    display: flex;
    justify-content: center;
  }

  &__showMoreIcon {
    fill: #1c6434;
    width: 5.8rem;
    height: 3.3rem;

    &Full {
      @extend .card__showMoreIcon;

      fill: $color-white;
      transform: rotate(180deg);
      margin-top: 0.5rem;
    }
  }

  &__buttonClose {
    background-color: inherit;
    position: absolute;
    right: 2rem;
    top: 2rem;
    border: none;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    transition: all 0.2s ease-out;

    &Full {
      @extend .card__buttonClose;

      background-color: rgba(0, 0, 0, 0.7);
    }
  }

  &__closeIcon {
    fill: #7e8d94;
    width: 4rem;
    height: 4rem;

    &Full {
      @extend .card__closeIcon;

      fill: $color-white;
    }
  }
}

.map {
  width: 100%;
  height: calc(100vh - 28rem);

  &__button {
    position: absolute;
    width: 6.5rem;
    height: 6.5rem;
    right: 3rem;
    top: 3rem;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-green;
    z-index: 1200;

    &Icon {
      width: 4rem;
      height: 4rem;
    }

    &Plus {
      @extend .map__button;

      top: 11rem;
      font-size: 6.4rem;

      &Disabled {
        @extend .map__buttonPlus;

        background: #c4c4c4;
        box-shadow: none;
      }
    }

    &Minus {
      @extend .map__button;

      top: 19rem;
      font-size: 6.4rem;
      padding-bottom: 0.6rem;

      &Disabled {
        @extend .map__buttonMinus;

        background: #c4c4c4;
        box-shadow: none;
      }
    }
  }
}
